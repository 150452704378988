import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronDown,
  faChevronUp,
  faQuestionCircle,
} from "@fortawesome/free-solid-svg-icons";

const Faq = () => {
  // Une liste de questions et réponses
  const faqs = [
    {
      question: "Qu'est-ce que SlideIn ?",
      answer: (
        <div>
          <p>
            <strong>SlideIn</strong> est une application web conçue pour
            simplifier le processus de fusion d'images.
          </p>{" "}
          <br />
          <p>
            Que ce soit en mode horizontal ou vertical, SlideIn offre une
            expérience utilisateur intuitive et performante. <br /> <br /> Elle
            convient parfaitement aux professionnels comme aux particuliers qui
            recherchent une solution rapide et efficace pour combiner des
            images.
          </p>
        </div>
      ),
    },
    {
      question: "Comment fonctionne SlideIn ?",
      answer: (
        <div>
          <p>
            SlideIn offre une expérience conviviale pour le téléchargement et
            l'organisation de vos images. Vous pouvez choisir : <br />
            <br />
          </p>
          <ul className="list-disc ml-6">
            <li>Le mode de fusion : horizontal ou vertical</li>
            <li>L'espacement entre les images</li>
            <li>La résolution souhaitée</li>
            <li>L'option de redimensionnement</li>
          </ul>
          <br />
          <p>
            Après avoir configuré ces paramètres, SlideIn fusionne vos images
            pour créer une image finale que vous pouvez télécharger.
          </p>
        </div>
      ),
    },
    {
      question: "Quels sont les différents types de comptes sur SlideIn ?",
      answer: (
        <div>
          <p>SlideIn propose deux principaux types de comptes :</p> <br />
          <ul className="list-disc ml-6">
            <li>
              <strong>Standard</strong> : Convient aux utilisateurs classiques,
              qu'ils soient inscrits ou non. Les utilisateurs avec ce type de
              compte peuvent effectuer des créations gratuitement, mais un
              filigrane 'slidein.fr' sera appliqué sur les images.
            </li>{" "}
            <br />
            <li>
              <strong>Premium</strong> : Destiné aux utilisateurs inscrits qui
              ont acheté des crédits. Ces utilisateurs peuvent créer des images
              sans le filigrane SlideIn. Ce type de compte est idéal pour ceux
              qui cherchent à utiliser l'application pour des besoins
              professionnels ou fréquents.
            </li>
          </ul>
        </div>
      ),
    },
    {
      question: "Pourquoi il y a un filigrane sur les images créées ?",
      answer: (
        <div>
          <p>
            Le filigrane 'slidein.fr' est ajouté sur les images créées par les
            utilisateurs gratuits, c'est-à-dire ceux qui ne sont pas inscrits ou
            qui n'ont pas de crédits.
          </p>
        </div>
      ),
    },
    {
      question: "Comment puis-je retirer le filigrane de mes images ?",
      answer: (
        <div>
          <p>Pour supprimer le filigrane de vos créations :</p> <br />
          <ul className="list-disc ml-6">
            <li>Inscrivez-vous sur SlideIn si ce n'est pas déjà fait.</li>
            <li>Achetez des crédits pour votre compte.</li>
          </ul>{" "}
          <br />
          <p>
            Une fois que vous disposez de crédits, vos futures créations seront
            exemptes de filigrane.
          </p>
        </div>
      ),
    },

    {
      question: "Est-ce que SlideIn offre un historique de mes créations ?",
      answer: (
        <div>
          <p>Oui, SlideIn fournit une fonctionnalité d'historique pour :</p>{" "}
          <br />
          <ul className="list-disc ml-6">
            <li>Visualiser vos créations passées.</li>
            <li>Télécharger des créations antérieures.</li>
            <li>Supprimer des créations de votre historique.</li>
          </ul>{" "}
          <br />
          <p>
            Notez que l'accès à l'historique des créations nécessite une
            inscription à SlideIn.
          </p>
        </div>
      ),
    },

    {
      question: "Comment accéder à l'historique de mes créations ?",
      answer: (
        <div>
          <p>
            Pour voir l'historique de vos créations, connectez-vous à votre
            compte SlideIn et naviguez jusqu'à la section 'Historique des
            créations'.
          </p>
        </div>
      ),
    },

    {
      question:
        "Puis-je télécharger ou supprimer plusieurs créations en même temps ?",
      answer: (
        <div>
          <p>Dans l'historique des créations, vous avez la possibilité de :</p>{" "}
          <br />
          <ul className="list-disc ml-6">
            <li>Sélectionner plusieurs créations simultanément.</li>
            <li>
              Choisir de télécharger ou de supprimer en groupe les créations
              sélectionnées.
            </li>
          </ul>
        </div>
      ),
    },

    {
      question: "Quelles sont les options de redimensionnement disponibles ?",
      answer: (
        <div>
          <p>
            SlideIn propose deux options de redimensionnement pour vos images :
          </p>{" "}
          <br />
          <ul className="list-disc ml-6">
            <li>
              Avec Redimensionnement pour uniformiser la taille des images.
            </li>
            <li>
              Sans Redimensionnement pour conserver les dimensions originales.
            </li>
          </ul>
        </div>
      ),
    },

    {
      question: "Comment puis-je changer mon mot de passe ?",
      answer: (
        <div>
          <p>
            Pour changer votre mot de passe, rendez-vous dans la section 'Mon
            Compte' de SlideIn :
          </p>{" "}
          <br />
          <ul className="list-disc ml-6">
            <li>Entrez votre mot de passe actuel.</li>
            <li>Choisissez et confirmez votre nouveau mot de passe.</li>
          </ul>
        </div>
      ),
    },

    {
      question: "Comment supprimer mon compte SlideIn ?",
      answer: (
        <div>
          <p>Pour supprimer votre compte SlideIn :</p> <br />
          <ul className="list-disc ml-6">
            <li>Aller à 'Mon Compte'.</li>
            <li>Sélectionnez 'Supprimer mon compte'.</li>
          </ul>{" "}
          <br />
          <p>
            Attention, cette action supprimera également toutes vos créations
            enregistrées.
          </p>
        </div>
      ),
    },

  ];

  const [openFAQ, setOpenFAQ] = useState({});

  const toggleFAQ = (index) => {
    setOpenFAQ((prevOpenFAQ) => ({
      ...prevOpenFAQ,
      [index]: !prevOpenFAQ[index],
    }));
  };

  return (
    <div className="container mx-auto py-8 max-w-4xl mb-24 px-4">
      <h2 className="text-3xl font-bold text-center uppercase mb-12">
        Foire aux questions
      </h2>
      <div className="bg-white p-4 sm:p-12 rounded-lg shadow">
        {faqs.map((faq, index) => (
          <div key={index} className="rounded-lg mb-4 last:mb-0">
            <button
              onClick={() => toggleFAQ(index)}
              className="flex items-start justify-between w-full"
            >
              <span className="flex items-center space-x-2 text-left">
                <FontAwesomeIcon icon={faQuestionCircle} className="text-[#28C1B8]" />
                <span className="font-semibold">{faq.question}</span>
              </span>
              <FontAwesomeIcon
                icon={openFAQ[index] ? faChevronUp : faChevronDown}
                className="self-center"
              />
            </button>
            {openFAQ[index] && (
              <div className="mt-2 p-4 border-t border-gray-200">
                {faq.answer}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Faq;
