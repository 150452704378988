import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock, faCheckCircle } from "@fortawesome/free-solid-svg-icons";

const PasswordResetPage = () => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (password !== confirmPassword) {
      setMessage("Les mots de passe ne correspondent pas.");
      return;
    }

    try {
      const token = window.location.pathname.split("/")[2]; // Extrait le token de l'URL
      const response = await fetch(
        `http://localhost:5025/password-reset/reset-password/${token}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ password }),
        }
      );
      const data = await response.json();
      setMessage(data.message);
    } catch (error) {
      console.error(
        "Erreur lors de la réinitialisation du mot de passe",
        error
      );
    }
  };

  return (
    <div className="container mx-auto px-4 md:px-20 xl:px-96 py-8">
      <div className="bg-white p-8 rounded-lg shadow-lg">
        <h2 className="text-3xl font-bold text-center uppercase mb-12">
          Réinitialiser le mot de passe
        </h2>
        <form onSubmit={handleSubmit} className="space-y-4">
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              <FontAwesomeIcon icon={faLock} className="text-gray-400 mr-2" />
              Nouveau mot de passe
            </label>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="outline-none w-full border border-gray-300 rounded-lg px-3 py-2"
              placeholder="Nouveau mot de passe"
            />
          </div>
          <div className="mb-6">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              <FontAwesomeIcon
                icon={faCheckCircle}
                className="text-gray-400 mr-2"
              />
              Confirmer le mot de passe
            </label>
            <input
              type="password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              className="outline-none w-full border border-gray-300 rounded-lg px-3 py-2"
              placeholder="Confirmer le mot de passe"
            />
          </div>
          <button
            type="submit"
            className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 mb-4 rounded transition duration-300"
          >
            Réinitialiser le mot de passe
          </button>
          {message && <p className="text-center font-bold">{message}</p>}
        </form>
      </div>
    </div>
  );
};

export default PasswordResetPage;
