import React, { useState, useEffect } from "react";
import axios from "axios";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

const CreationsFavorites = () => {
  const [favorites, setFavorites] = useState([]);
  const [totalCreations, setTotalCreations] = useState(0);
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const imageStyle = {
    height: '300px', // Hauteur fixe pour toutes les images
    width: '100%', // Largeur s'adapte à la taille du conteneur
    objectFit: 'contain' // Garde les proportions des images
  };

  const handleDownload = async (imageUrl) => {
    try {
      const response = await fetch(imageUrl);
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "favorite_image.png";
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    } catch (error) {
      console.error("Erreur lors du téléchargement de l'image", error);
    }
  };

  useEffect(() => {
    const jwtToken = localStorage.getItem("jwtToken");
    const headers = { Authorization: `Bearer ${jwtToken}` };

    const fetchFavorites = async () => {
      try {
        const response = await axios.get(
          "https://slidein.fr/api/creations/favorites",
          { headers }
        );
        setFavorites(response.data.favorites);
      } catch (error) {
        console.error(
          "Erreur lors de la récupération des créations favorites",
          error
        );
      }
    };

    const fetchTotalCreations = async () => {
      try {
        const response = await axios.get(
          "https://slidein.fr/api/creations",
          { headers }
        );
        setTotalCreations(response.data.creations.length);
      } catch (error) {
        console.error(
          "Erreur lors de la récupération du nombre total de créations",
          error
        );
      }
    };

    fetchFavorites();
    fetchTotalCreations();
  }, []);

  const handleCreationClick = (index) => {
    setCurrentImageIndex(index);
    setLightboxOpen(true);
  };

  return (
    <div className="container mx-auto p-4 text-center mb-20">
      <h1 className="text-4xl font-bold my-6 mb-12 uppercase">
        Créations Favorites
      </h1>
      <div className=" mb-2">
        <p>
          Vous avez {totalCreations} créations au total et {favorites.length}{" "}
          créations dans vos favoris.
        </p>
      </div>
      {favorites.length > 0 ? (
        <div className="grid grid-cols-1 gap-4">
          {favorites.map((favorite, index) => (
            <div
              key={favorite.id}
              className="bg-white p-4 rounded shadow-md cursor-pointer mb-4"
              onClick={() => handleCreationClick(index)}
            >
              <img
                src={favorite.image_url}
                alt={`Favorite ${index}`}
                style={imageStyle}
              />
              <p className="mt-4">
                Création créée le{" "}
                {new Date(favorite.created_at).toLocaleDateString("fr-FR")}
              </p>
              <p>
                Création ajoutée aux favoris le{" "}
                {favorite.is_favorite
                  ? new Date(favorite.favorited_at).toLocaleDateString("fr-FR")
                  : "Pas encore favorisée"}
              </p>
            </div>
          ))}
        </div>
      ) : (
        <div className="bg-white p-8 text-lg mt-4">
          <p>
            Vous n'avez actuellement aucune création enregistrée dans vos
            favoris.
          </p>
          <p className="mt-4">
            Pour ajouter des créations à vos favoris, veuillez vous rendre dans
            la section{" "}
            <a href="/mon-compte" className="text-blue-600 hover:text-blue-800">
              Mon Compte
            </a>
            , puis accéder à l'historique de vos créations.
          </p>
          <p className="mt-4">
            Vous pourrez y sélectionner et ajouter les créations de votre choix
            à vos favoris.
          </p>
        </div>
      )}

      {lightboxOpen && (
        <Lightbox
          mainSrc={favorites[currentImageIndex].image_url}
          onCloseRequest={() => setLightboxOpen(false)}
          nextSrc={
            favorites[(currentImageIndex + 1) % favorites.length].image_url
          }
          prevSrc={
            favorites[
              (currentImageIndex + favorites.length - 1) % favorites.length
            ].image_url
          }
          onMovePrevRequest={() =>
            setCurrentImageIndex(
              (currentImageIndex + favorites.length - 1) % favorites.length
            )
          }
          onMoveNextRequest={() =>
            setCurrentImageIndex((currentImageIndex + 1) % favorites.length)
          }
          toolbarButtons={[
            <button
              onClick={() =>
                handleDownload(favorites[currentImageIndex].image_url)
              }
              style={{
                marginRight: "4px",
                background: "none",
                border: "none",
                color: "white",
                fontSize: "19px",
                cursor: "pointer",
              }}
            >
              <i className="fa fa-download"></i>
            </button>,
          ]}
        />
      )}
    </div>
  );
};

export default CreationsFavorites;
