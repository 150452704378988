import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { logOut } from "../store/userSlice";
import { useDispatch } from "react-redux";
import HistoryModal from "./HistoryModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faKey, faHistory, faTrash, faPlus } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";

const Account = () => {
  const [userEmail, setUserEmail] = useState("");
  const [accountType, setAccountType] = useState("");
  const [isUserAuthenticated, setIsUserAuthenticated] = useState(false);
  const [userCreationsCount, setUserCreationsCount] = useState(0);
  const [favoritesCount, setFavoritesCount] = useState(0);
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [showChangePassword, setShowChangePassword] = useState(false);
  const [message, setMessage] = useState("");
  const [userCredits, setUserCredits] = useState(0);
  const token = localStorage.getItem("jwtToken");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleAddCreditsClick = () => {
    document.getElementById("paymentModal").showModal();
  };

  const closePaymentModal = () => {
    document.getElementById('paymentModal').close();
  };

  const modalStyle = {
    width: '100%',
    height: 'auto',
    maxWidth: '1200px',
  };

  // Fonction pour extraire user_id à partir du JWT
  const getUserIDFromJWT = () => {
    const token = localStorage.getItem("jwtToken");
    if (!token) {
      console.error("JWT non trouvé dans localStorage");
      return null;
    }

    try {
      const base64Url = token.split('.')[1];
      const base64 = base64Url.replace('-', '+').replace('_', '/');
      const payload = JSON.parse(window.atob(base64));

      if (!payload.user_id) {
        console.error("user_id manquant dans le payload du JWT");
        return null;
      }

      return payload.user_id;
    } catch (error) {
      console.error("Erreur lors de la décodage du JWT: ", error);
      return null;
    }
  };

  // Gestion du paiement avec user_id
  const handlePayment = async (amount) => {
    const user_id = getUserIDFromJWT();
    if (!user_id) {
      console.error("User ID introuvable");
      return;
    }

    try {
      const response = await axios.post("https://slidein.fr/api/create-checkout-session", {
        amount: amount,
        user_id: user_id
      });
      window.location.href = response.data.url;
    } catch (error) {
      console.error("Erreur lors de la création de la session de paiement", error);
    }
  };

  useEffect(() => {
    const fetchUserData = async () => {
      if (token) {
        try {
          const response = await fetch("https://slidein.fr/api/auth/get-user", {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          const data = await response.json();
          if (response.ok) {
            setUserEmail(data.email);
            setUserCreationsCount(data.creationsCount);
            setAccountType(data.accountType);
            setUserCredits(data.credits);
          } else {
          }
        } catch (error) {
          console.error("Fetch error: ", error);
        }
      }
    };

    fetchUserData();
  }, [token]);

  useEffect(() => {
    setIsUserAuthenticated(!!token);
  }, [token, accountType]);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    if (params.has("session_id")) {
      const sessionId = params.get("session_id");
      fetch(`https://slidein.fr/auth/success?session_id=${sessionId}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then(response => {
          if (response.ok) {
            return response.json();
          } else {
            throw new Error('Erreur de paiement');
          }
        })
        .then(data => {
          if (data.message === "Paiement réussi et crédits ajoutés") {
            // Mise à jour de l'état de l'utilisateur ici si nécessaire
            navigate("/mon-compte");
          } else {
            // Gérer les erreurs
          }
        })
        .catch(error => console.error("Erreur lors de la validation de la session: ", error));
    }
  }, [navigate, token]);

  const handleChangePassword = async (event) => {
    event.preventDefault();
    if (newPassword !== confirmNewPassword) {
      setMessage("Les mots de passe ne correspondent pas.");
      return;
    }

    if (token) {
      try {
        const response = await fetch(
          "https://slidein.fr/api/auth/change-password",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
              current_password: currentPassword,
              new_password: newPassword,
            }),
          }
        );

        const data = await response.json();
        setMessage(data.message);

        if (response.ok) {
          setMessage(
            "Mot de passe changé avec succès. Veuillez vous reconnecter."
          );
          setTimeout(() => {
            localStorage.removeItem("jwtToken");
            navigate("/auth");
          }, 2000);
        }
      } catch (error) {
        console.error("Erreur lors du changement de mot de passe: ", error);
      }
    }
  };

  const handleDeleteAccount = async () => {
    const isConfirmed = window.confirm(
      "Êtes-vous sûr de vouloir supprimer votre compte ? Toutes vos créations seront également supprimées."
    );

    if (isConfirmed && token) {
      try {
        const response = await fetch(
          "https://slidein.fr/api/auth/delete-account",
          {
            method: "DELETE",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const data = await response.json();
        setMessage(data.message);

        if (response.ok) {
          setTimeout(() => {
            localStorage.removeItem("jwtToken");
            dispatch(logOut());
            navigate("/");
          }, 3000);
        }
      } catch (error) {
        console.error("Erreur lors de la suppression du compte: ", error);
      }
    }
  };

  const toggleChangePasswordForm = () => {
    setShowChangePassword(!showChangePassword);
  };

  const fetchFavorites = async () => {
    if (token) {
      try {
        const response = await fetch(
          "https://slidein.fr/api/creations/favorites",
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const data = await response.json();
        if (response.ok) {
          setFavoritesCount(data.favorites.length);
        } else {
          console.error(
            "Erreur lors de la récupération des créations favorites"
          );
        }
      } catch (error) {
        console.error("Erreur de fetch", error);
      }
    }
  };

  useEffect(() => {
    fetchFavorites();
  });

  const updateCreationsCount = async () => {
    if (token) {
      try {
        const response = await fetch("https://slidein.fr/api/auth/get-user", {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const data = await response.json();
        if (response.ok) {
          setUserCreationsCount(data.creationsCount);
        } else {
          console.error(
            "Erreur lors de la récupération du nombre de créations"
          );
        }
      } catch (error) {
        console.error("Erreur de fetch", error);
      }
    }
  };

  return (
    <div className="container mx-auto px-4 md:px-20 xl:px-80 py-8">
      <div className="bg-white p-8 rounded-lg shadow-lg">
        <h1 className="text-3xl font-bold text-center uppercase mb-12">
          Mon Compte
        </h1>
        <p>Email : {userEmail}</p>
        <div
          className="tooltip tooltip-right"
          data-tip={
            accountType === "standard"
              ? "Votre compte permet un nombre de créations illimités avec le filigrane (watermark) SlideIn"
              : accountType === "premium"
                ? "Votre compte PREMIUM vous permet de n'avoir aucun filigrane sur vos créations."
                : ""
          }
        >
          <p>
            Type de compte : <b>{accountType}</b>{" "}
            <span className="text-red-500">*</span>
          </p>
        </div>
        <p>Vos crédits restants : {userCredits}</p>
        <p>Nombre de créations actuelles : {userCreationsCount}</p>
        <p>Nombre de créations favorites : {favoritesCount}</p>

        <button
          onClick={toggleChangePasswordForm}
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-4 mr-4"
        >
          <FontAwesomeIcon icon={faKey} className="mr-2" /> Changer le mot de
          passe
        </button>

        <button
          onClick={() => document.getElementById("history_modal").showModal()}
          className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded mt-4 mr-4"
        >
          <FontAwesomeIcon icon={faHistory} className="mr-2" /> Historique des
          créations
        </button>

        {showChangePassword && (
          <form onSubmit={handleChangePassword} className="mt-4">
            <div className="mb-4">
              <input
                type="password"
                value={currentPassword}
                onChange={(e) => setCurrentPassword(e.target.value)}
                placeholder="Mot de passe actuel"
                className="w-full py-2 px-3 border border-gray-300 rounded-lg"
              />
            </div>
            <div className="flex gap-4 mb-4">
              <input
                type="password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                placeholder="Nouveau mot de passe"
                className="w-1/2 py-2 px-3 border border-gray-300 rounded-lg"
              />
              <input
                type="password"
                value={confirmNewPassword}
                onChange={(e) => setConfirmNewPassword(e.target.value)}
                placeholder="Confirmez le nouveau mot de passe"
                className="w-1/2 py-2 px-3 border border-gray-300 rounded-lg"
              />
            </div>
            <button
              type="submit"
              className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
            >
              Modifier mon mot de passe
            </button>
          </form>
        )}

        <button
          onClick={handleDeleteAccount}
          className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded mt-4 mb-4"
        >
          <FontAwesomeIcon icon={faTrash} className="mr-2" /> Supprimer mon
          compte
        </button>
        {message && <p>{message}</p>}
        <HistoryModal
          id="history_modal"
          token={token}
          onUpdate={updateCreationsCount}
        />
        {/* Bouton Ajouter des crédits */}
        {isUserAuthenticated && (accountType === "premium" || accountType === "standard") && (
          <div className="flex justify-center">
            <button
              onClick={handleAddCreditsClick}
              className="bg-[#28C1B8] text-white font-bold py-4 px-12 rounded-full shadow-lg hover:bg-[#117A65] hover:shadow-xl transition ease-in-out duration-500 mt-4"
            >
              <FontAwesomeIcon icon={faPlus} className="mr-2" /> Ajouter des crédits
            </button>
          </div>
        )}
      </div>
      {/* Modal de paiement */}
      <dialog id="paymentModal" className="modal" onClick={closePaymentModal}>
        <div className="modal-box" style={modalStyle} onClick={e => e.stopPropagation()}>
          <button onClick={closePaymentModal} className="absolute top-0 right-4 m-2 text-2xl hover:text-red-500">&times;</button>
          <h3 className="font-bold text-lg mb-5">Choisissez le montant de vos crédits</h3>
          <div className="grid grid-cols-1 sm:grid-cols-3 gap-4">
            {/* Carte pour 1€ */}
            <div className="card">
              <img src="/logo-stripe.png" alt="SlideIn Logo" />
              <h4 className="text-xl font-bold">SlideIn - 10 crédits</h4>
              <p>Permet d'avoir 10 images sans filigrane.</p>
              <button onClick={() => handlePayment(100)} className="btn bg-blue-500 hover:bg-blue-700 text-white">Payer 1€</button>
            </div>

            {/* Carte pour 3€ */}
            <div className="card">
              <img src="/logo-stripe.png" alt="SlideIn Logo" />
              <h4 className="text-xl font-bold">3€ - 30 crédits</h4>
              <p>Permet d'avoir 30 images sans filigrane.</p>
              <button onClick={() => handlePayment(300)} className="btn bg-blue-500 hover:bg-blue-700 text-white">Payer 3€</button>
            </div>

            {/* Carte pour 5€ */}
            <div className="card">
              <img src="/logo-stripe.png" alt="SlideIn Logo" />
              <h4 className="text-xl font-bold">5€ - 50 crédits</h4>
              <p>Permet d'avoir 50 images sans filigrane.</p>
              <button onClick={() => handlePayment(500)} className="btn bg-blue-500 hover:bg-blue-700 text-white">Payer 5€</button>
            </div>
          </div>
        </div>
      </dialog>
    </div>
  );
};

export default Account;
