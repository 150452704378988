import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { store } from "./store/store";
import "./index.css";
import App from "./App";

const stripePromise = loadStripe("pk_live_51O44LTAWsAAjcwJXK5DbUPjgHEoYHaOMtP1ZwNZv8KeIAVwJKfOor6QRHgeVw0x4wrndRGBUTZrfgaxgA5vcxtWB00Jp1Ebsd7");

const root = createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <Elements stripe={stripePromise}>
      <App />
    </Elements>
  </Provider>
);