// src/store/userSlice.js
import { createSlice } from "@reduxjs/toolkit";

export const userSlice = createSlice({
  name: "user",
  initialState: {
    isLoggedIn: false,
  },
  reducers: {
    logIn: (state) => {
      state.isLoggedIn = true;
    },
    logOut: (state) => {
      state.isLoggedIn = false;
    },
    checkLoginStatus: (state) => {
      state.isLoggedIn = !!localStorage.getItem("jwtToken");
    },
  },
});

export const { logIn, logOut, checkLoginStatus } = userSlice.actions;

export default userSlice.reducer;
