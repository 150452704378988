import React, { useState, useEffect, useCallback } from "react";
import { jwtDecode } from 'jwt-decode';
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { logOut, checkLoginStatus } from "../store/userSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHome,
  faQuestionCircle,
  faEnvelope,
  faSignInAlt,
  faBars,
  faUser,
  faSignOutAlt,
  faStar,
} from "@fortawesome/free-solid-svg-icons";
import "./Navbar.css";

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const isLoggedIn = useSelector((state) => state.user.isLoggedIn);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogOut = useCallback(() => {
    localStorage.removeItem("jwtToken");
    dispatch(logOut());
    navigate("/");
  }, [dispatch, navigate]);

  useEffect(() => {
    const token = localStorage.getItem("jwtToken");
    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        const currentTime = Date.now() / 1000;
        if (decodedToken.exp < currentTime) {
          handleLogOut();
        } else {
          // Si le token est toujours valide, mettez à jour l'état de connexion
          dispatch(checkLoginStatus());
        }
      } catch (error) {
        console.error("Erreur de décodage du JWT: ", error);
      }
    } else {
      dispatch(logOut());
    }
  }, [handleLogOut, dispatch]);

  return (
    <nav className="bg-white shadow-lg mb-4">
      <div className="max-w-8xl mx-auto px-6">
        <div className="flex justify-between items-center py-3">
          {/* Logo */}
          <div className="flex items-center">
            <Link to="/" className="flex items-center py-2 px-2">
              <img src="/logo.png" alt="Logo" className="h-[58px] w-[100px]" />
            </Link>
          </div>
          {/* Liens du menu pour tablettes et ordinateurs */}
          <div className="hidden md:flex flex-grow justify-center items-center md:space-x-5 lg:space-x-20">
            <Link
              to="/"
              className="text-gray-500 font-semibold hover-text-cyan transition duration-300 text-sm md:text-md uppercase"
            >
              <FontAwesomeIcon icon={faHome} className="mr-1" /> Accueil
            </Link>
            {isLoggedIn && (
              <Link
                to="/mon-compte"
                className="text-gray-500 font-semibold hover-text-cyan transition duration-300 text-sm md:text-md uppercase"
              >
                <FontAwesomeIcon icon={faUser} className="mr-1" /> Mon compte
              </Link>
            )}
            {isLoggedIn && (
              <Link
                to="/creationsfavorites"
                className="text-gray-500 font-semibold hover-text-cyan transition duration-300 text-sm md:text-md uppercase"
              >
                <FontAwesomeIcon icon={faStar} className="mr-1" /> Créations
                Favorites
              </Link>
            )}
            <Link
              to="/faq"
              className="text-gray-500 font-semibold hover-text-cyan transition duration-300 text-sm md:text-md uppercase"
            >
              <FontAwesomeIcon icon={faQuestionCircle} className="mr-1" /> FAQ
            </Link>
            <Link
              to="/contact"
              className="text-gray-500 font-semibold hover-text-cyan transition duration-300 text-sm md:text-md uppercase"
            >
              <FontAwesomeIcon icon={faEnvelope} className="mr-1" /> Contact
            </Link>
          </div>
          {/* Bouton du menu burger pour smartphone */}
          <div className="flex justify-end md:hidden">
            <button onClick={() => setIsMenuOpen(!isMenuOpen)}>
              <FontAwesomeIcon icon={faBars} className="text-2xl" />
            </button>
          </div>
          {/* Liens de connexion / déconnexion */}
          <div className="hidden md:flex justify-end">
            {isLoggedIn ? (
              <button
                onClick={handleLogOut}
                className="text-gray-500 font-semibold hover-text-cyan transition duration-300 text-sm uppercase"
              >
                <FontAwesomeIcon icon={faSignOutAlt} className="mr-1" />{" "}
                Déconnexion
              </button>
            ) : (
              <Link
                to="/auth"
                className="text-gray-500 font-semibold hover-text-cyan transition duration-300 text-sm uppercase"
              >
                <FontAwesomeIcon icon={faSignInAlt} className="mr-1" />{" "}
                Connexion - Inscription
              </Link>
            )}
          </div>
        </div>
        {/* Menu burger déroulant pour smartphone */}
        {isMenuOpen && (
          <div className="md:hidden">
            <a
              href="/"
              className="block text-gray-500 font-semibold hover-text-cyan transition duration-300 text-md uppercase"
            >
              <FontAwesomeIcon icon={faHome} className="mr-1" /> Accueil
            </a>
            {isLoggedIn && (
              <a
                href="/mon-compte"
                className="block text-gray-500 font-semibold hover-text-cyan transition duration-300 text-md uppercase"
              >
                <FontAwesomeIcon icon={faUser} className="mr-1" /> Mon compte
              </a>
            )}
            {isLoggedIn && (
              <a
                href="/creations-favorites"
                className="block text-gray-500 font-semibold hover-text-cyan transition duration-300 text-md uppercase"
              >
                <FontAwesomeIcon icon={faStar} className="mr-1" /> Créations
                Favorites
              </a>
            )}
            <a
              href="/faq"
              className="block text-gray-500 font-semibold hover-text-cyan transition duration-300 text-md uppercase"
            >
              <FontAwesomeIcon icon={faQuestionCircle} className="mr-1" /> FAQ
            </a>
            <Link
              to="/contact"
              className="block text-gray-500 font-semibold hover-text-cyan transition duration-300 text-md uppercase"
            >
              <FontAwesomeIcon icon={faEnvelope} className="mr-1" /> Contact
            </Link>
            {isLoggedIn ? (
              <button
                onClick={handleLogOut}
                className="block text-gray-500 font-semibold hover-text-cyan transition duration-300 text-md uppercase"
              >
                <FontAwesomeIcon icon={faSignOutAlt} className="mr-1" />{" "}
                Déconnexion
              </button>
            ) : (
              <Link
                to="/auth"
                className="block text-gray-500 font-semibold hover-text-cyan transition duration-300 text-md uppercase"
              >
                <FontAwesomeIcon icon={faSignInAlt} className="mr-1" /> Connexion - Inscription
              </Link>
            )}
          </div>
        )}
      </div>
    </nav>
  );
};

export default Navbar;
