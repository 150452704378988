import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./Accueil.css";

function Accueil() {
  const resultImageRef = useRef(null);
  const [images, setImages] = useState([]);
  const [mode, setMode] = useState("auto");
  const [spaceBetween, setSpaceBetween] = useState(0);
  const [resolution, setResolution] = useState("normal");
  const [resizeOption, setResizeOption] = useState("with_resize");
  const [imagePreviews, setImagePreviews] = useState([]);
  const [effectiveMode, setEffectiveMode] = useState(mode);
  const token = localStorage.getItem("jwtToken");
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();
  const [generatedImageUrl, setGeneratedImageUrl] = useState("");
  const [creationId, setCreationId] = useState(null);
  const [showRedirectMessage, setShowRedirectMessage] = useState(false);
  const [userGroup, setUserGroup] = useState("FREE");
  const [isFavorited, setIsFavorited] = useState(false);
  const [userCredits, setUserCredits] = useState(0);
  const [imageDimensions, setImageDimensions] = useState({
    width: 0,
    height: 0,
  });

  const fetchUserCredits = async (token) => {
    try {
      const response = await fetch("https://slidein.fr/api/auth/get-user", {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      if (response.ok) {
        setUserCredits(data.credits);
      } else {
        console.error("Erreur lors de la récupération des crédits de l'utilisateur");
      }
    } catch (error) {
      console.error("Erreur de fetch", error);
    }
  };

  useEffect(() => {
    if (token) {
      fetchUserCredits(token);
    }
  }, [token]);

  const handlePremiumClick = () => {

    if (!isUserAuthenticated) {
      setShowRedirectMessage(true);
      setTimeout(() => {
        navigate('/auth');
      }, 2000);
    } else if (userGroup === 'standard') {
      openPaymentModal();
    }
  };

  const modalStyle = {
    width: '100%',
    height: 'auto',
    maxWidth: '1200px',
  };

  const openPaymentModal = () => {
    const modal = document.getElementById('paymentModal');
    if (modal) {
      modal.showModal();
    } else {
      console.error("Modal not found");
    }
  };

  const closePaymentModal = () => {
    document.getElementById('paymentModal').close();
  };

  const [isUserAuthenticated, setIsUserAuthenticated] = useState(false);

  useEffect(() => {
    if (token) {
      const fetchUserData = async () => {
        try {
          const response = await fetch("https://slidein.fr/api/auth/get-user", {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          const data = await response.json();
          if (response.ok) {
            // Mettre à jour les crédits de l'utilisateur
            setUserCredits(data.credits);
          } else {
            console.error("Erreur lors de la récupération des données de l'utilisateur");
          }
        } catch (error) {
          console.error("Erreur de fetch", error);
        }
      };

      fetchUserData();
    }
  }, [token]);

  useEffect(() => {
    const jwtToken = localStorage.getItem("jwtToken");
    setIsUserAuthenticated(!!jwtToken);
    if (!!jwtToken) {
      axios.get('https://slidein.fr/api/user-group', {
        headers: { Authorization: `Bearer ${jwtToken}` }
      })
        .then(response => {
          setUserGroup(response.data.user_group);
          setUserCredits(response.data.credits);
        })
        .catch(error => {
          console.error('Erreur lors de la récupération du groupe utilisateur', error);
        });
    }
  }, []);

  const handleFileDrop = (event) => {
    event.preventDefault();
    const files = event.dataTransfer.files;
    if (files && files.length > 0) {
      handleImageUpload({ target: { files: files } });
    }
  };

  useEffect(() => {
    if (images.length === 0) {
      return;
    }

    let horizontalCount = 0;
    let verticalCount = 0;

    const dimensionsPromises = images.map((image) => {
      const img = new Image();
      img.src = URL.createObjectURL(image);
      return new Promise((resolve) => {
        img.onload = () => {
          const width = img.naturalWidth;
          const height = img.naturalHeight;
          img.naturalWidth > img.naturalHeight
            ? horizontalCount++
            : verticalCount++;
          resolve({ width, height });
        };
      });
    });

    Promise.all(dimensionsPromises).then((originalDims) => {
      const modeToUse =
        mode === "auto"
          ? horizontalCount > verticalCount
            ? "horizontal"
            : "vertical"
          : mode;

      setEffectiveMode(modeToUse);

      const dims = originalDims.map((dim) => {
        if (resizeOption === "with_resize") {
          if (modeToUse === "horizontal") {
            const targetHeight = Math.min(...originalDims.map((d) => d.height));
            return {
              width: (dim.width * targetHeight) / dim.height,
              height: targetHeight,
            };
          } else if (modeToUse === "vertical") {
            const targetWidth = Math.min(...originalDims.map((d) => d.width));
            return {
              width: targetWidth,
              height: (dim.height * targetWidth) / dim.width,
            };
          }
        }
        return dim;
      });

      let totalWidth = 0;
      let totalHeight = 0;

      if (modeToUse === "horizontal") {
        totalWidth = Math.round(
          dims.reduce((sum, dim) => sum + dim.width, 0) +
          spaceBetween * (dims.length - 1)
        );
        totalHeight = Math.round(Math.max(...dims.map((d) => d.height)));
      } else {
        totalWidth = Math.round(Math.max(...dims.map((d) => d.width)));
        totalHeight = Math.round(
          dims.reduce((sum, dim) => sum + dim.height, 0) +
          spaceBetween * (dims.length - 1)
        );
      }

      if (resolution === "high") {
        totalWidth *= 2;
        totalHeight *= 2;
      }

      setImageDimensions({ width: totalWidth, height: totalHeight });
    });
  }, [images, mode, spaceBetween, resolution, resizeOption]);

  useEffect(() => {
    if (generatedImageUrl) {
      window.scrollTo({
        top: document.documentElement.scrollHeight,
        behavior: "smooth",
      });
    }
  }, [generatedImageUrl]);

  const scaleFactor = resolution === "high" ? 1 : 1;
  const adjustedSpaceBetween = Math.round((spaceBetween * scaleFactor) / 4.8);

  const handleImageUpload = (event) => {
    const files = [...event.target.files];
    setImages(files);
    setErrorMessage("");

    const filePreviews = files.map((file) => {
      return URL.createObjectURL(file);
    });
    setImagePreviews(filePreviews);
  };

  const handleModeChange = (event) => {
    setMode(event.target.value);
  };

  const handleSpaceChange = (event) => {
    setSpaceBetween(event.target.value);
  };

  const handleResolutionChange = (event) => {
    setResolution(event.target.value);
  };

  const handleResizeOptionChange = (event) => {
    setResizeOption(event.target.value);
  };

  const handleFavorite = async () => {
    const jwtToken = localStorage.getItem("jwtToken");

    if (!jwtToken || !creationId) {
      return;
    }

    try {
      const response = await axios.post(
        "https://slidein.fr/api/creations/favorite",
        { creation_id: creationId },
        { headers: { Authorization: `Bearer ${jwtToken}` } }
      );

      if (response.status === 200) {
        toast("Ajout en favori effectué", { type: "success" });
        setIsFavorited(true);
      }
    } catch (error) {
      console.error("Erreur lors de l'ajout aux favoris", error);
    }
  };

  const previewContainerStyle = {
    display: "flex",
    flexDirection: effectiveMode === "horizontal" ? "row" : "column",
    gap: `${adjustedSpaceBetween}px`,
    overflowX: effectiveMode === "horizontal" ? "auto" : "hidden",
    overflowY: effectiveMode === "vertical" ? "auto" : "hidden",
    alignItems: resizeOption === "without_resize" ? "flex-start" : "center",
    justifyContent: "center",
    maxWidth: "100vw", // Limite la largeur à la largeur de la vue
  };


  const imagePreviewStyle = (preview) => {
    if (resizeOption === "with_resize") {
      if (effectiveMode === "horizontal") {
        return {
          maxHeight: "150px",
          maxWidth: "100%",
          objectFit: "contain",
        };
      } else {
        return {
          maxHeight: "100%",
          maxWidth: "300px",
          objectFit: "contain",
        };
      }
    } else {
      if (effectiveMode === "horizontal") {
        return {
          maxWidth: "300px",
          maxHeight: "300px",
          objectFit: "contain",
        };
      } else {
        return {
          maxHeight: "150px",
          objectFit: "contain",
        };
      }
    }
  };

  const handleSubmit = async () => {
    if (images.length === 0) {
      setErrorMessage("Aucune image n'a été sélectionnée");
      return;
    }

    setErrorMessage("");
    const formData = new FormData();
    formData.append("mode", mode);
    formData.append("spaceBetween", spaceBetween);
    formData.append("resolution", resolution);
    formData.append("resizeOption", resizeOption);
    images.forEach((image) => {
      formData.append("images", image);
    });

    const jwtToken = localStorage.getItem("jwtToken");
    const headers = {
      "Content-Type": "multipart/form-data",
    };
    if (jwtToken) {
      headers.Authorization = `Bearer ${jwtToken}`;
    }

    try {
      const response = await axios.post(
        "https://slidein.fr/api/",
        formData,
        { headers }
      );

      setGeneratedImageUrl(response.data.image_url);

      if (resultImageRef.current) {
        resultImageRef.current.scrollIntoView({ behavior: "smooth" });
      }

      if (jwtToken && response.data.image_url) {
        try {
          const saveResponse = await axios.post(
            "https://slidein.fr/api/save-creation",
            { image_url: response.data.image_url },
            { headers: { Authorization: `Bearer ${jwtToken}` } }
          );
          if (saveResponse.data.success) {
            setCreationId(saveResponse.data.creation_id);
            fetchUserCredits(jwtToken);
          } else {
            console.error("Erreur lors de l'enregistrement de la création");
          }
        } catch (error) {
          console.error(
            "Erreur lors de l'enregistrement de la création",
            error
          );
        }
      }
    } catch (error) {
      console.error("Erreur lors de l'envoi des images", error);
      setErrorMessage("Erreur lors de l'envoi des images");
    }
  };

  const redimensionnementLabel = (
    <div
      className="tooltip tooltip-right"
    >
      Redimensionnement <span style={{ color: "red", cursor: "help" }}>*</span>
    </div>
  );

  const handleDownload = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(generatedImageUrl);
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "image_resultat.png";
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Erreur lors du téléchargement de l'image", error);
    }
  };

  const handleNewCreation = () => {
    setImages([]);
    setImagePreviews([]);
    setGeneratedImageUrl("");
    setCreationId(null);
    setIsFavorited(false);
    setImageDimensions({ width: 0, height: 0 });
  };

  // Fonction pour extraire user_id à partir du JWT
  const getUserIDFromJWT = () => {
    const token = localStorage.getItem("jwtToken");
    if (!token) {
      console.error("JWT non trouvé dans localStorage");
      return null;
    }

    try {
      const base64Url = token.split('.')[1];
      const base64 = base64Url.replace('-', '+').replace('_', '/');
      const payload = JSON.parse(window.atob(base64));

      if (!payload.user_id) {
        console.error("user_id manquant dans le payload du JWT");
        return null;
      }

      return payload.user_id;
    } catch (error) {
      console.error("Erreur lors de la décodage du JWT: ", error);
      return null;
    }
  };

  // Gestion du paiement avec user_id
  const handlePayment = async (amount) => {
    const user_id = getUserIDFromJWT();
    if (!user_id) {
      console.error("User ID introuvable");
      return;
    }

    try {
      const response = await axios.post("https://slidein.fr/api/create-checkout-session", {
        amount: amount,
        user_id: user_id
      });
      window.location.href = response.data.url;
    } catch (error) {
      console.error("Erreur lors de la création de la session de paiement", error);
    }
  };


  useEffect(() => {
    if (isUserAuthenticated) {
      axios.get('https://slidein.fr/api/user-group', {
        headers: { Authorization: `Bearer ${localStorage.getItem("jwtToken")}` }
      })
        .then(response => {
          setUserGroup(response.data.user_group);
        })
        .catch(error => {
          console.error('Erreur lors de la récupération du groupe utilisateur', error);
        });
    }
  }, [isUserAuthenticated]);

  return (
    <div className="container mx-auto p-4 text-center mb-6">
      <h1 className="text-4xl font-bold my-6 mb-12">
        FUSIONNEZ, COMPAREZ ET CRÉEZ AVEC SLIDEIN
      </h1>
      <div>
        <ToastContainer />
        <div className="bg-white p-8 rounded shadow-md mx-auto max-w-5xl">
          <p>
            Vous avez souvent besoin de fusionner des images pour des
            présentations, des projets ou des posts sur les réseaux sociaux ?{" "}
            <br />
            <br />
            SlideIn est là pour simplifier cette tâche. Cela peut paraître
            simple, mais nécessite souvent des logiciels complexes comme
            Photoshop ou Canva.
          </p>
          <p className="mt-4">
            SlideIn est un outil intuitif et performant qui vous permet de
            fusionner des images horizontalement ou verticalement en quelques
            clics. Il est conçu pour les professionnels qui recherchent une
            solution rapide et efficace sans avoir à manipuler de lourds
            logiciels coûteux.
          </p>
          <p className="mt-4">
            La version gratuite inclut un filigrane SlideIn, que vous pouvez
            retirer en ajoutant des crédits à votre compte. Découvrez la
            simplicité et l'efficacité de SlideIn dès aujourd'hui.
          </p>

          <div className="example-images-container mt-8 flex flex-wrap justify-center items-center gap-6 md:gap-24">
            <div className="text-center">
              <img
                src="/demo-horizontal.webp"
                alt="Exemple Horizontal"
                className="max-w-full h-auto md:max-w-xs"
              />
              <p className="mt-4">Exemple en mode horizontal</p>
            </div>
            <div className="text-center md:flex md:flex-col md:items-center">
              <img
                src="/demo-vertical.webp"
                alt="Exemple Vertical"
                className="max-w-full h-auto md:max-w-[75px]"
              />
              <p className="mt-4 md:mt-2 md:ml-0">Exemple en mode vertical</p>
            </div>
          </div>


        </div>
        <div className="bg-white p-8 rounded shadow-md mx-auto max-w-5xl mt-8 mb-12">
          <h2 className="text-2xl font-bold mb-8 uppercase">
            Prévisualisation
          </h2>
          <div className="mb-8">
            <label htmlFor="mode-select" className="mr-2">
              Mode :
            </label>
            <select
              id="mode-select"
              value={mode}
              onChange={handleModeChange}
              className="select select-bordered w-full max-w-xs"
            >
              <option value="auto">Détection Automatique</option>
              <option value="horizontal">Horizontal</option>
              <option value="vertical">Vertical</option>
            </select>
          </div>

          {images.length > 0 && (
            <div className="bg-black p-6 rounded shadow-md mb-16">
              <div className="flex flex-col md:flex-row justify-around text-white">
                <div className="mb-4 md:mb-0">
                  <label htmlFor="space-range" className="block mb-2">
                    Espacement entre les images :
                  </label>
                  <input
                    type="range"
                    id="space-range"
                    min="0"
                    max="200"
                    value={spaceBetween}
                    onChange={handleSpaceChange}
                    className="w-full md:w-38 mt-4"
                  />
                </div>
                <div className="mb-4 md:mb-0">
                  <label htmlFor="resolution-select" className="block mb-2">
                    Résolution :
                  </label>
                  <select
                    id="resolution-select"
                    value={resolution}
                    onChange={handleResolutionChange}
                    className="bg-white text-black p-2 rounded w-full md:w-auto"
                  >
                    <option value="normal">Résolution Normale</option>
                    <option value="high">Résolution Élevée</option>
                  </select>
                </div>
                <div>
                  <label htmlFor="resize-option-select" className="block mb-2">
                    {redimensionnementLabel}
                  </label>
                  <select
                    id="resize-option-select"
                    value={resizeOption}
                    onChange={handleResizeOptionChange}
                    className="bg-white text-black p-2 rounded w-full md:w-auto"
                  >
                    <option value="with_resize">Avec Redimensionnement</option>
                    <option value="without_resize">
                      Sans Redimensionnement
                    </option>
                  </select>
                </div>
              </div>
            </div>
          )}
          {/* Affichage des prévisualisations des images */}
          {imagePreviews.length > 0 && (
            <div className="mt-8" style={previewContainerStyle}>
              {imagePreviews.map((preview, index) => (
                <img
                  key={index}
                  src={preview}
                  alt={`Prévisualisation ${index}`}
                  style={imagePreviewStyle(preview)}
                />
              ))}
            </div>
          )}

          {/* Afficher le file drop area seulement si aucune image n'a été sélectionnée */}
          {imagePreviews.length === 0 && (
            <div className="mt-8">
              <div
                className="file-drop-area border-dashed border-2 border-gray-300 rounded p-4 flex justify-center items-center relative"
                style={{ height: "150px" }}
                onDragOver={(e) => e.preventDefault()}
                onDragEnter={(e) => e.preventDefault()}
                onDragLeave={(e) => e.preventDefault()}
                onDrop={handleFileDrop}
              >
                <input
                  type="file"
                  multiple
                  onChange={handleImageUpload}
                  style={{
                    position: "absolute",
                    width: "100%",
                    height: "100%",
                    top: 0,
                    left: 0,
                    opacity: 0,
                    cursor: "pointer",
                    zIndex: 2,
                  }}
                />
                <i
                  className="fa fa-upload"
                  style={{ fontSize: "24px", marginRight: "10px" }}
                ></i>
                <span style={{ zIndex: 1 }}>
                  Glissez et déposez des images ici ou cliquez pour sélectionner
                  des images
                </span>
              </div>
            </div>
          )}

          {/* Affichage des dimensions de l'image */}
          {imageDimensions.width > 0 && (
            <div>
              <p className="mt-4">
                Dimensions : {imageDimensions.width}px x{" "}
                {imageDimensions.height}
                px
              </p>
            </div>
          )}

          <button
            className="bg-blue-500 hover:bg-blue-600 text-white p-2 pr-4 pl-4 rounded mt-10"
            onClick={handleSubmit}
          >
            <i
              className="fa-solid fa-wand-magic-sparkles"
              style={{ marginRight: "10px" }}
            ></i>
            Créer l'image
          </button>
          {images.length > 0 && (
            <button
              onClick={handleNewCreation}
              className="bg-purple-500 hover:bg-purple-600 text-white py-2.5 px-4 rounded ml-4"
            >
              <i className="fa fa-plus-circle mr-2"></i> Nouvelle création
            </button>
          )}
          {errorMessage && <p className="text-red-500 mt-4">{errorMessage}</p>}
        </div>

        {generatedImageUrl && (
          <div
            ref={resultImageRef}
            className="bg-white p-8 rounded shadow-md mx-auto max-w-5xl mt-8 mb-24"
          >
            <h2 className="text-2xl uppercase font-bold mb-8">
              Image de résultat
            </h2>
            <div style={{ marginBottom: "42px" }}>
              <img
                src={generatedImageUrl}
                alt=""
                style={{ maxWidth: "100%", height: "auto" }}
              />
            </div>
            {/* Afficher les crédits restants pour les utilisateurs premium */}
            {userGroup === "premium" && (
              <p className="mb-4">Crédits restants : {userCredits}</p>
            )}
            <a
              href={generatedImageUrl}
              onClick={handleDownload}
              className="bg-blue-500 hover:bg-blue-600 text-white p-3 rounded mr-4"
            >
              <i className="fa fa-download mr-2"></i>
              Télécharger l'image
            </a>
            <div
              className="tooltip"
              data-tip={
                !isUserAuthenticated
                  ? "Uniquement pour les utilisateurs identifiés"
                  : isFavorited
                    ? "Ajout en favori effectué"
                    : "Ajouter en favori"
              }
            >
              <button
                onClick={handleFavorite}
                className={`bg-green-500 hover:bg-green-600 text-white py-2.5 px-4 rounded ${(!isUserAuthenticated || isFavorited) &&
                  "opacity-50 cursor-not-allowed"
                  }`}
                disabled={!isUserAuthenticated || isFavorited}
              >
                <i className="fa fa-heart mr-2"></i>
                Ajouter en favori
              </button>
            </div>

            <button
              onClick={handleNewCreation}
              className="bg-purple-500 hover:bg-purple-600 text-white py-2.5 px-4 rounded ml-4 mb-8"
            >
              <i className="fa fa-plus-circle mr-2"></i> Nouvelle création
            </button>

            {/* Modal de paiement */}
            <dialog id="paymentModal" className="modal" onClick={closePaymentModal}>
              <div className="modal-box" style={modalStyle} onClick={e => e.stopPropagation()}>
                <button onClick={closePaymentModal} className="absolute top-0 right-4 m-2 text-2xl hover:text-red-500">&times;</button>
                <h3 className="font-bold text-lg mb-5">Choisissez le montant de vos crédits</h3>
                <div className="grid grid-cols-1 sm:grid-cols-3 gap-4">
                  {/* Carte pour 1€ */}
                  <div className="card">
                    <img src="/logo-stripe.png" alt="SlideIn Logo" />
                    <h4 className="text-xl font-bold">SlideIn - 10 crédits</h4>
                    <p>Permet d'avoir 10 images sans filigrane.</p>
                    <button onClick={() => handlePayment(100)} className="btn bg-blue-500 hover:bg-blue-700 text-white">Payer 1€</button>
                  </div>

                  {/* Carte pour 3€ */}
                  <div className="card">
                    <img src="/logo-stripe.png" alt="SlideIn Logo" />
                    <h4 className="text-xl font-bold">3€ - 30 crédits</h4>
                    <p>Permet d'avoir 30 images sans filigrane.</p>
                    <button onClick={() => handlePayment(300)} className="btn bg-blue-500 hover:bg-blue-700 text-white">Payer 3€</button>
                  </div>

                  {/* Carte pour 5€ */}
                  <div className="card">
                    <img src="/logo-stripe.png" alt="SlideIn Logo" />
                    <h4 className="text-xl font-bold">5€ - 50 crédits</h4>
                    <p>Permet d'avoir 50 images sans filigrane.</p>
                    <button onClick={() => handlePayment(500)} className="btn bg-blue-500 hover:bg-blue-700 text-white">Payer 5€</button>
                  </div>
                </div>
              </div>
            </dialog>

            {/* Bouton pour ouvrir le modal ou rediriger vers la page de création de compte */}

            {(!isUserAuthenticated || userGroup === 'FREE' || userGroup === 'standard') && (
              <div className="mt-8 p-4 border-4 rounded-full mb-4" style={{ borderColor: "#CACFD2" }}>
                <h3 className="text-3xl font-bold mb-4" style={{ color: "#28C1B8" }}>
                  Découvrez SlideIn PREMIUM
                </h3>
                <p className="mb-4 text-lg">
                  Passez à un niveau supérieur sans filigrane.
                </p>
                <button onClick={handlePremiumClick} className="bg-[#28C1B8] text-white font-bold py-4 px-12 rounded-full shadow-lg hover:bg-[#117A65] hover:shadow-xl transition ease-in-out duration-500">
                  Devenir membre PREMIUM
                </button>
                {showRedirectMessage && (
                  <p style={{ color: "red", marginTop: "20px" }}>
                    Vous devez être identifié ou inscrit. Redirection en cours...
                  </p>
                )}
              </div>
            )}

          </div>
        )}
      </div>
    </div>
  );
}

export default Accueil;