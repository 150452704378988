import React, { useState, useEffect } from "react";
import "./HistoryModal.css";

const HistoryModal = ({ id, token, onUpdate }) => {
  const [creations, setCreations] = useState([]);
  const [selectedCreations, setSelectedCreations] = useState(new Set());
  const [selectedAction, setSelectedAction] = useState("");

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(dateString).toLocaleDateString("fr-FR", options);
  };

  useEffect(() => {
    const closeModal = (event) => {
      if (event.target.id === id || event.key === "Escape") {
        const modal = document.getElementById(id);
        if (modal) {
          modal.close();
        }
      }
    };

    window.addEventListener("click", closeModal);
    window.addEventListener("keydown", closeModal);

    return () => {
      window.removeEventListener("click", closeModal);
      window.removeEventListener("keydown", closeModal);
    };
  }, [id]);

  useEffect(() => {
    const fetchCreations = async () => {
      try {
        const response = await fetch("https://slidein.fr/api/creations", {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const data = await response.json();
        if (response.ok) {
          setCreations(data.creations);
        } else {
          console.error(
            "Erreur lors du chargement des créations",
            data.message
          );
        }
      } catch (error) {
        console.error("Erreur de fetch", error);
      }
    };

    fetchCreations();
  }, [token]);

  const toggleSelection = (id) => {
    const newSelection = new Set(selectedCreations);
    if (newSelection.has(id)) {
      newSelection.delete(id);
    } else {
      newSelection.add(id);
    }
    setSelectedCreations(newSelection);
  };

  const toggleFavorite = async (creationId) => {
    try {
      // Log pour déboguer la requête envoyée
      console.log(
        "Envoi de la requête de favoris pour la création ID:",
        creationId
      );

      const response = await fetch(
        "https://slidein.fr/api/creations/favorite",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ creation_id: creationId }),
        }
      );

      if (response.ok) {
        // Log pour confirmer que la requête a réussi
        console.log(
          `La création ID ${creationId} a été mise à jour comme favori.`
        );

        // Mettre à jour l'état local pour refléter le changement
        setCreations(
          creations.map((c) =>
            c.id === creationId ? { ...c, is_favorite: !c.is_favorite } : c
          )
        );
      } else {
        // Log en cas d'échec de la requête
        console.error(
          "Échec de la mise à jour des favoris pour la création ID:",
          creationId
        );
      }
    } catch (error) {
      console.error("Erreur lors de la mise à jour des favoris", error);
    }
  };

  const handleDownload = async (url) => {
    try {
      const response = await fetch(url);
      const blob = await response.blob();
      const urlBlob = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = urlBlob;
      a.download = url.split("/").pop();
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(urlBlob);
      document.body.removeChild(a);
    } catch (error) {
      console.error("Erreur lors du téléchargement de l'image", error);
    }
  };

  const handleAction = async (action) => {
    let creationIds = Array.from(selectedCreations);

    if (action === "deleteSelected" || action === "deleteAll") {
      // Confirmation avant suppression
      const confirmMessage =
        action === "deleteAll"
          ? "Êtes-vous sûr de vouloir supprimer toutes les créations ?"
          : "Êtes-vous sûr de vouloir supprimer les créations sélectionnées ?";
      const confirmDelete = window.confirm(confirmMessage);

      if (confirmDelete) {
        if (action === "deleteAll") {
          creationIds = creations.map((c) => c.id);
        }
        try {
          const response = await fetch(
            "https://slidein.fr/api/delete-creation",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
              body: JSON.stringify({ creation_ids: creationIds }),
            }
          );
          const data = await response.json();
          if (response.ok) {
            setCreations(creations.filter((c) => !creationIds.includes(c.id)));
            setSelectedCreations(new Set());
            alert(data.message);
            onUpdate(); // Mettre à jour le compte des créations dans Account.js
          } else {
            alert(data.message);
          }
        } catch (error) {
          console.error("Erreur lors de la suppression", error);
        }
      }
    } else if (action === "downloadSelected" || action === "downloadAll") {
      if (action === "downloadAll") {
        creationIds = creations.map((c) => c.id);
      }
      creationIds.forEach((id) => {
        const creation = creations.find((c) => c.id === id);
        if (creation) handleDownload(creation.image_url);
      });
    }

    // Réinitialiser la sélection après l'action
    setSelectedAction("");
  };

  const handleCreationClick = (creationId) => {
    toggleSelection(creationId);
  };

  return (
    <dialog id={id} className="modal">
      <div className="modal-box w-auto max-w-full mr-10 ml-10">
        <h3 className="font-bold text-2xl text-center mt-4">
          Historique des créations
        </h3>

        <div className="flex justify-center">
          <select
            className="select select-bordered w-full max-w-xs my-4"
            value={selectedAction}
            onChange={(e) => handleAction(e.target.value)}
          >
            <option disabled value="">
              Actions groupées
            </option>
            <option value="deleteSelected">Supprimer la sélection</option>
            <option value="downloadSelected">Télécharger la sélection</option>
            <option value="deleteAll">Supprimer tout</option>
            <option value="downloadAll">Télécharger tout</option>
          </select>
        </div>

        {creations.length > 0 ? (
          <div className="grid-container">
            {creations.map((creation) => (
              <div
                key={creation.id}
                className="grid-item"
                onClick={() => handleCreationClick(creation.id)}
              >
                <div
                  className="favorite-icon"
                  onClick={(e) => {
                    e.stopPropagation();
                    toggleFavorite(creation.id);
                  }}
                >
                  {creation.is_favorite ? (
                    <i className="fas fa-heart filled"></i>
                  ) : (
                    <i className="far fa-heart"></i>
                  )}
                </div>
                <input
                  type="checkbox"
                  checked={selectedCreations.has(creation.id)}
                  onChange={(e) => {
                    e.stopPropagation();
                    toggleSelection(creation.id);
                  }}
                />
                <img src={creation.image_url} alt={`Création ${creation.id}`} />
                <p className="creation-date">
                  Créée le {formatDate(creation.created_at)}
                </p>
              </div>
            ))}
          </div>
        ) : (
          <div className="text-center py-4">
            <p>Vous n'avez pas encore effectué de création.</p>
          </div>
        )}

        <div className="modal-action">
          <form method="dialog">
            <button className="bg-red-500 hover:bg-red-600 text-white py-2 px-4 rounded">
              Fermer
            </button>
          </form>
        </div>
      </div>
    </dialog>
  );
};

export default HistoryModal;
