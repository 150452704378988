import React from "react";

const Footer = () => {
  return (
    <footer className="bg-white text-center text-gray-700 py-4 fixed inset-x-0 bottom-0 overflow-hidden w-full">
      © 2024 SlideIn. Tous droits réservés.
    </footer>

  );
};

export default Footer;
