import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faTag,
  faComment,
  faPaperPlane,
} from "@fortawesome/free-solid-svg-icons";

const Contact = () => {
  const [formData, setFormData] = useState({
    email: "",
    subject: "",
    message: "",
  });

  const [confirmationMessage, setConfirmationMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!formData.email || !formData.subject || !formData.message) {
      setErrorMessage("Tous les champs sont requis.");
      setTimeout(() => setErrorMessage(""), 3000);
      return;
    }

    try {
      const response = await fetch("https://slidein.fr/api/contact", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: new URLSearchParams(formData),
      });

      const data = await response.json();
      setConfirmationMessage(data.message);
      setTimeout(() => setConfirmationMessage(""), 3000);
    } catch (error) {
      console.error("Erreur lors de l'envoi du formulaire", error);
      setErrorMessage("Une erreur est survenue lors de l'envoi de l'email.");
      setTimeout(() => setErrorMessage(""), 3000);
    }
  };

  return (
    <div className="container mx-auto px-4 md:px-20 xl:px-96 py-8">
      {" "}
      <h2 className="text-3xl font-bold text-center uppercase mb-12">
        Contactez-moi
      </h2>
      <div className="bg-white p-8 rounded-lg shadow-lg">
        <form>
          {/* Email */}
          <div className="mb-4">
            <label
              htmlFor="email"
              className="block text-gray-700 text-sm font-bold mb-2"
            >
              <FontAwesomeIcon
                icon={faEnvelope}
                className="text-gray-400 mr-1"
              />{" "}
              {/* Icône */}
              Email
            </label>
            <input
              type="email"
              id="email"
              name="email"
              onChange={handleChange}
              className="outline-none w-full border border-gray-300 rounded-lg px-3 py-2"
              placeholder="Votre email"
            />
          </div>

          {/* Sujet */}
          <div className="mb-4">
            <label
              htmlFor="subject"
              className="block text-gray-700 text-sm font-bold mb-2"
            >
              <FontAwesomeIcon icon={faTag} className="text-gray-400 mr-1" />{" "}
              {/* Icône */}
              Sujet
            </label>
            <input
              type="text"
              id="subject"
              name="subject"
              onChange={handleChange}
              className="outline-none w-full border border-gray-300 rounded-lg px-3 py-2"
              placeholder="Sujet du message"
            />
          </div>

          {/* Message */}
          <div className="mb-6">
            <label
              htmlFor="message"
              className="block text-gray-700 text-sm font-bold mb-2"
            >
              <FontAwesomeIcon
                icon={faComment}
                className="text-gray-400 mr-1"
              />{" "}
              {/* Icône */}
              Message
            </label>
            <textarea
              id="message"
              name="message"
              onChange={handleChange}
              className="outline-none w-full border border-gray-300 rounded-lg px-3 py-2"
              placeholder="Votre message"
              rows="4"
            ></textarea>
          </div>

          {/* Bouton Envoyer */}
          <button
            type="submit"
            onClick={handleSubmit}
            className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 mb-4 rounded transition duration-300"
          >
            <FontAwesomeIcon icon={faPaperPlane} className="mr-2" /> Envoyer
          </button>

          {confirmationMessage && (
            <div className="text-center font-bold" style={{ color: "#28C1B8" }}>
              {confirmationMessage}
            </div>
          )}
          {errorMessage && (
            <div className="text-center text-red-500 font-bold">
              {errorMessage}
            </div>
          )}
        </form>
      </div>
    </div>
  );
};

export default Contact;
