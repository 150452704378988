import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faLock,
  faSignInAlt,
  faUserPlus,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { logIn, logOut } from "../store/userSlice";
import { jwtDecode } from "jwt-decode";

const AuthPage = () => {
  const [isLogin, setIsLogin] = useState(true);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");
  const [messageStyle, setMessageStyle] = useState("");
  const [showResetForm, setShowResetForm] = useState(false);
  const [resetEmail, setResetEmail] = useState("");

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleSubmit = async (event) => {
    event.preventDefault();
    const userData = {
      email: email,
      password: password,
    };

    try {
      const endpoint = isLogin ? "login" : "signup";
      const response = await fetch(`https://slidein.fr/api/auth/${endpoint}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(userData),
      });
      const data = await response.json();
      setMessage(data.message);
      if (response.ok) {
        localStorage.setItem("jwtToken", data.token);
        setMessageStyle("text-green-500");
        dispatch(logIn());
        setTimeout(() => {
          navigate("/mon-compte");
        }, 1000);
      } else {
        setMessageStyle("text-red-500");
      }
    } catch (error) {
      console.error("Erreur de connexion", error);
    }
  };

  const handleForgotPasswordClick = () => {
    setShowResetForm(!showResetForm);
  };

  const handleResetPassword = async (event) => {
    event.preventDefault();
    if (!resetEmail) {
      setMessage(
        "Veuillez entrer votre email pour réinitialiser le mot de passe."
      );
      setMessageStyle("text-red-500");
      return;
    }

    try {
      const response = await fetch(
        `https://slidein.fr/api/password-reset/request-reset`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email: resetEmail }),
        }
      );

      const data = await response.json();

      if (response.ok) {
        // Gestion du succès
        setMessage(
          `Un email de réinitialisation a été envoyé à l'adresse ${resetEmail}`
        );
        setMessageStyle("text-green-500");
        setShowResetForm(false);
      } else {
        // Gestion de l'erreur
        setMessage(
          data.message || "Cette adresse email n'existe pas, veuillez vérifier."
        );
        setMessageStyle("text-red-500");
      }
    } catch (error) {
      console.error("Erreur lors de la demande de réinitialisation", error);
    }
  };

  useEffect(() => {
    // Déplacez la fonction ici à l'intérieur de useEffect
    const checkTokenValidityAndRefresh = async () => {
      const token = localStorage.getItem("jwtToken");
      if (token) {
        const decodedToken = jwtDecode(token);
        const currentTime = Date.now() / 1000;
        if (decodedToken.exp - currentTime < 3600) {
          // Si le token expire dans moins d'une heure
          try {
            const response = await fetch(
              "https://slidein.fr/api/auth/refresh-token",
              {
                method: "POST",
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              }
            );
            const data = await response.json();
            if (response.ok) {
              localStorage.setItem("jwtToken", data.token);
            } else {
              dispatch(logOut());
              navigate("/auth");
            }
          } catch (error) {
            console.error("Erreur lors du rafraîchissement du token", error);
          }
        }
      }
    };

    const intervalId = setInterval(checkTokenValidityAndRefresh, 5 * 60 * 1000);
    return () => clearInterval(intervalId);
  }, [dispatch, navigate]);

  return (
    <div className="container mx-auto px-4 md:px-20 xl:px-96 py-8">
      <div className="bg-white p-8 rounded-lg shadow-lg">
        <h2 className="text-3xl font-bold text-center uppercase mb-12">
          {isLogin ? "Identification" : "Inscription"}
        </h2>

        <form className="space-y-4" onSubmit={handleSubmit}>
          <div className="flex items-center border border-gray-300 rounded-lg px-3 py-2">
            <FontAwesomeIcon icon={faEnvelope} className="mr-2" />
            <input
              type="email"
              placeholder="Email"
              className="outline-none w-full"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="flex items-center border border-gray-300 rounded-lg px-3 py-2">
            <FontAwesomeIcon icon={faLock} className="mr-2" />
            <input
              type="password"
              placeholder="Mot de passe"
              className="outline-none w-full"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>

          <div className="flex flex-col items-start">
            {isLogin && (
              <p
                className="text-blue-500 text-sm underline hover:text-blue-600 cursor-pointer self-start"
                onClick={handleForgotPasswordClick}
              >
                Mot de passe oublié ?
              </p>
            )}

            {showResetForm && (
              <div className="mt-4 self-start">
                <input
                  type="email"
                  placeholder="Entrez votre email"
                  value={resetEmail}
                  onChange={(e) => setResetEmail(e.target.value)}
                  className="border border-gray-300 rounded-lg px-3 py-2 w-full md:w-96"
                />
                <button
                  onClick={handleResetPassword}
                  className="bg-teal-500 hover:bg-teal-600 text-white text-sm font-bold py-2.5 px-4 rounded transition duration-300 mt-2 ml-4"
                >
                  Réinitialiser le mot de passe
                </button>
              </div>
            )}

            {message && (
              <div className={`${messageStyle} mt-4 self-start`}>{message}</div>
            )}

            <button
              type="submit"
              className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded transition duration-300 mt-4 self-center flex items-center justify-center"
            >
              <FontAwesomeIcon
                icon={isLogin ? faSignInAlt : faUserPlus}
                className="mr-2"
              />
              {isLogin ? "Connexion" : "S'inscrire"}
            </button>
          </div>
        </form>

        <div className="text-center mt-4">
          <button
            onClick={() => setIsLogin(!isLogin)}
            className="text-blue-600 font-bold text-sm underline hover:text-blue-700"
          >
            {isLogin ? "Pas encore inscrit ?" : "Déjà inscrit ?"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default AuthPage;
