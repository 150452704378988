import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { jwtDecode } from 'jwt-decode';
import { useDispatch } from "react-redux";
import { logOut } from "./store/userSlice";
import Navbar from "./components/Navbar";
import Faq from "./components/Faq";
import Contact from "./components/Contact";
import Footer from "./components/Footer";
import AuthPage from "./components/AuthPage";
import Account from "./components/Account";
import PasswordResetPage from "./components/PasswordResetPage";
import Accueil from "./components/Accueil";
import CreationsFavorites from "./components/CreationsFavorites";

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    const interval = setInterval(() => {
      const token = localStorage.getItem('jwtToken');
      if (token) {
        try {
          const tokenExpiration = jwtDecode(token).exp;
          const currentTime = Date.now() / 1000;
          if (tokenExpiration < currentTime) {
            localStorage.removeItem('jwtToken');
            dispatch(logOut());
          }
        } catch (error) {
          console.error("Erreur de décodage du JWT: ", error);
        }
      }
    }, 60000);

    return () => clearInterval(interval);
  }, [dispatch]);

  return (
    <Router>
      <div className="flex flex-col min-h-screen">
        <Navbar />
        <main className="flex-grow">
          <Routes>
            <Route path="/" element={<Accueil />} />
            <Route path="/mon-compte" element={<Account />} />
            <Route path="/creationsfavorites" element={<CreationsFavorites />} />
            <Route path="/faq" element={<Faq />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/auth" element={<AuthPage />} />
            <Route path="/reset-password/:token" element={<PasswordResetPage />} />
          </Routes>
        </main>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
